// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const images = require.context('../images', true)

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import 'bootstrap';
import 'jquery'
import 'popper.js'
import "@fortawesome/fontawesome-free/css/all"
import '../styles/application';
import './bootstrap_template.js';

import 'slick-carousel'

$(function(){
    $('.customer-logos').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        lazyLoad: 'ondemand',
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 3
            }
        }]
    });

    const position = {
        about: $('#about').offset().top,
        services: $('#services').offset().top,
        portfolio: $('#portfolio').offset().top,
        contact: $('#contact').offset().top,
    }

    const navBtn = {
        about: $('#about-btn'),
        services: $('#services-btn'),
        portfolio: $('#portfolio-btn'),
        contact: $('#contact-btn'),
    }

    /*
        Navigation Buttons
    */

    $('.back-to-top, .logo, #home-btn').on('click', function(){
        $(window).scrollTop(0);
    });

    $('#about-btn').on('click', function(){
        $(window).scrollTop(position.about);
    });

    $('#services-btn').on('click', function(){
        $(window).scrollTop(position.services);
    });

    $('#portfolio-btn').on('click', function(){
        $(window).scrollTop(position.portfolio );
    });

    $('#contact-btn, .get-started-btn').on('click', function(){
        $(window).scrollTop(position.contact);
    });

    /*
        Navbar item highlighting
    */

    function clearActiveNavbar(){
        $('.nav-link, active').removeClass('active');
    }

    $(window).on('scroll', function(){
        clearActiveNavbar();
        $('#home').addClass('active');

        for (const section in position) {
            if ($(window).scrollTop() >= position[section]-100){
                clearActiveNavbar();
                navBtn[section].addClass('active');
            }
        }
    })
});
